function loadSliders() {
	$('.testimonials .carousel-inner').slick({
		speed: 500,
		fade: true,
		arrows: false,
		cssEase: 'linear'
	});
}

var sliderScript = document.getElementById("deferredSlickScript");

sliderScript.addEventListener('load', function () {
	loadSliders();
});

$(document).ready(function () {

	$("img.has-tooltip").tooltip({
		title: function () {
			return $(this).attr("alt");
		},
		placement: "auto"
	});

	$("a.has-tooltip").tooltip({
		placement: "auto"
	});

	$('.testimonials__button--prev').click(function () {
		$('.testimonials .carousel').carousel("prev");
	});

	$('.testimonials__button--next').click(function () {
		$('.testimonials .carousel').carousel("next");
	});

	function fixHeights() {
		$(".RotatorTestimonialContent").sameHeight();
	}

	setTimeout(fixHeights, 300);

	$(window).on("resize", function () {
		setTimeout(fixHeights, 300);
	});

	$('.testimonials__button--prev').click(function () {
		$('.testimonials .carousel-inner').slick('slickPrev');
	});

	$('.testimonials__button--next').click(function () {
		$('.testimonials .carousel-inner').slick('slickNext');
	});
});

